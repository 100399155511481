import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import { isIOS } from 'react-device-detect';
import Cookies from 'js-cookie';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Footer from '../components/common/footer/Footer';
import CompanyHeader from '../components/common/company-header/CompanyHeader';
import FullHeightWrapper from '../components/common/full-height-wrapper/FullHeightWrapper';
import { GlobalState } from '../state/@types/redux.interface';
import PasswordInput from '../components/authorize/PasswordInput';
import BasicButton from '../components/common/basic-button/BasicButton';
import { passwordAuthorizeToEventAction } from '../state/authorize.reducer';
import KeySVG from '../images/authorize/key.svg';
import PatternBar from '../components/authorize/PatternBar';
import GreyCheck from '../images/authorize/greyCheck.svg';
import GreenCheck from '../images/authorize/greenCheck.svg';
import { setEventLoadingStatusAction } from '../state/event.reducer';
import { getLocalStorageItem } from '../_utils/localStorage';

const StyledLoginWrapper = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border-bottom-left-radius: 35px;
  border-bottom-right-radius: 35px;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 0 35px 20px;
  @media(min-width: 1000px) {
	max-width: 400px;
	padding: 0;
  }
  ::-webkit-scrollbar {
    display: none;
  }
`;
const StyledText = styled.p`
	color: ${({ theme }) => theme.colors.fontBlue};
	font-size: 0.75rem;
	line-height: 1.42rem;
`;

const StyledButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	left: 0;
	margin-bottom: 80px;
	width: 100%;
  	@media(min-width: 1000px) {
	  margin-top: 40px;
	}
`;
const StyledAuthorizeHeader = styled.h3`
	color: ${({ theme }) => theme.colors.fontBlue};
	font-size: 1.49rem;
	font-weight: 800;
	margin-bottom: 0.71rem;
`;
const RegisterWrapper = styled.div`
  @media(max-width: 600px) {
    height: 100%;
    position: absolute;
    top: 0;

  @media(min-width: 1000px) {
     height: 52vh;
  }
    &.goTop {
		padding-bottom: 80px;
    	transition: all .3s;
    	top: -170px;
    }
  }
  	
`;
const ChangePasswordButton = styled(BasicButton)`
  -webkit-tap-highlight-color: transparent;
	&.invalid {
      background-color: ${({ theme }) => theme.colors.lightGreyBackground};
      color: ${({ theme }) => theme.colors.fontLightGrey};
      cursor: pointer;
	  
	  :active {
        background-color: ${({ theme }) => theme.colors.mediumWhite};
        color: ${({ theme }) => theme.colors.lightPurple};
      }
	}
`;

const RegisterPage = () => {
	const dispatch = useDispatch();
	const [password, setPassword] = useState<string>('');
	const [isError, setIsError] = useState<boolean>(false);
	const [isTop, setTop] = useState(false);
	const { voterEventAccess } = useSelector((state: GlobalState) => state.authorize);

	const bigLetters = /^(?=.*[A-Z])/;
	const hasBigLetters = bigLetters.test(String(password));

	const specialLetters = /^(?=.*[!@#$%?^&*;<>()_+":])/;
	const hasSpecialLetters = specialLetters.test(String(password));

	const passwordValidate = password.length >= 8 && hasBigLetters && hasSpecialLetters;

	useEffect(() => {
		dispatch(setEventLoadingStatusAction(false));
	}, []);

	const onSubmit = async () => {
		if (password.length < 8 && password.length > 0) {
			setIsError(true);
		} else {
			dispatch(passwordAuthorizeToEventAction(getLocalStorageItem('eventHash'), {
				password,
				code: getLocalStorageItem('code'),
				login: getLocalStorageItem('login'),
			}));
		}
	};

	useEffect(() => {
		if (voterEventAccess) {
			dispatch(setEventLoadingStatusAction(true));
			Cookies.remove('isCode', { path: '/' });
			navigate('/room');
		}
	}, [voterEventAccess]);

	const onKeyDown = (e) => {
		if (e.keyCode === 13) {
			onSubmit();
		}
	};
	const pushInputToTop = () => {
		if (!isIOS) {
			setTop(true);
		}
	};

	return (
		<Layout>
			<RegisterWrapper className={isTop ? 'goTop' : ''}>
				<SEO
					description="Wyborek | Głosuj szybko i łatwo!"
					title="Utwórz Hasło"
				/>
				<CompanyHeader />

				<FullHeightWrapper>
					<StyledLoginWrapper>
						<StyledAuthorizeHeader>
							<img
								alt="Key"
								src={KeySVG}
								style={{ marginRight: '10px' }}
							/>
							Utwórz hasło
						</StyledAuthorizeHeader>
						<StyledText>
							Utwórz własne hasło
							aby dołączyć do głosowania.
							{' '}
							<strong> Pamiętaj aby zachować hasło</strong>
						</StyledText>
						<PasswordInput
							errorVisible={(!passwordValidate && password.length > 0) || isError}
							label="Hasło"
							onKeyDown={onKeyDown}
							password={password}
							pushInputToTop={pushInputToTop}
							setPassword={(e) => setPassword(e.target.value)}
						/>
						<PatternBar
							content="8+ znaków"
							src={password.length > 7 ? GreenCheck : GreyCheck}
						/>
						<PatternBar
							content="Przynajmniej 1 duża litera"
							src={!hasBigLetters ? GreyCheck : GreenCheck}
						/>
						<PatternBar
							content="Przynajmniej 1 znak specjalny"
							src={!hasSpecialLetters ? GreyCheck : GreenCheck}
						/>
					</StyledLoginWrapper>
					<StyledButtonWrapper>
						<ChangePasswordButton
							className={passwordValidate ? '' : 'invalid'}
							onClick={passwordValidate ? onSubmit : null}
						>
							utwórz hasło
						</ChangePasswordButton>
					</StyledButtonWrapper>
				</FullHeightWrapper>
				<Footer
					withRegulations
				/>
			</RegisterWrapper>
		</Layout>
	);
};

export default RegisterPage;
